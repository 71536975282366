
import { defineComponent, PropType, Ref, ref, computed } from "vue";
import { DateRange, formattedDateRange, mask, locale } from "@/utils/date";

export default defineComponent({
    props: {
        label: String,
        modelValue: Object as PropType<DateRange>,
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const qDateProxy: Ref<any> = ref(null);
        const range = computed({
            get: () => props.modelValue,
            set: (value) => {
                qDateProxy.value?.hide();
                emit("update:modelValue", value);
            },
        });
        const rangeText = computed(() => (range.value ? formattedDateRange(range.value) : null));
        const title = computed(() => {
            if (range.value) {
                return formattedDateRange(range.value);
            }
            return "Valitse päivämäärä";
        });

        return { range, rangeText, mask, locale, title, qDateProxy };
    },
});
