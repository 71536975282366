import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_input, {
    label: _ctx.label,
    outlined: "",
    dense: "",
    modelValue: _ctx.rangeText
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_q_icon, { name: "fas fa-calendar-alt" })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_q_popup_proxy, {
        ref: "qDateProxy",
        "transition-show": "scale",
        "transition-hide": "scale"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_date, {
            modelValue: _ctx.range,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.range = $event)),
            title: _ctx.title,
            mask: _ctx.mask,
            locale: _ctx.locale,
            "first-day-of-week": "1",
            range: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _withDirectives(_createVNode(_component_q_btn, {
                  label: "Sulje",
                  color: "light-text",
                  flat: "",
                  "no-caps": ""
                }, null, 512), [
                  [_directive_close_popup]
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "title", "mask", "locale"])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["label", "modelValue"]))
}