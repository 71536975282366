import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    modelValue: _ctx.valvontatyyppi,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.valvontatyyppi = $event)),
    outlined: "",
    options: _ctx.valvontatyypit,
    dense: "",
    label: "Valvontatyyppi",
    "dropdown-icon": "fas fa-chevron-down",
    "options-dense": "",
    "option-value": "id",
    "option-label": "name",
    clearable: "",
    "clear-icon": "fas fa-times"
  }, null, 8, ["modelValue", "options"]))
}