
import { defineComponent, computed, PropType } from "vue";
import PalveluyksikkoNameInput from "@/modules/palveluhaku/components/input/PalveluyksikkoNameInput.vue";
import LocationInput from "@/modules/palveluhaku/components/input/LocationInput.vue";
import ValvontatyyppiInput from "@/modules/valvontatiedot/components/input/ValvontatyyppiInput.vue";
import OrganisaatioInput from "@/components/input/OrganisaatioInput.vue";
import DateRangeInput from "@/components/input/DateRangeInput.vue";
import { ValvontahakuForm } from "@/modules/valvontatiedot/@types/ValvontahakuForm";

export default defineComponent({
    components: {
        PalveluyksikkoNameInput,
        LocationInput,
        ValvontatyyppiInput,
        DateRangeInput,
        OrganisaatioInput,
    },
    props: {
        modelValue: Object as PropType<ValvontahakuForm>,
        showHuomioitavaa: Boolean,
        onClick: { type: Function, required: true },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const form: any = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });

        return {
            form,
        };
    },
});
