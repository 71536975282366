
import { defineComponent, PropType, computed } from "vue";
import { multipleSelectionLabel } from "@/modules/palveluhaku/utils/input-utils";
import { VALVONTA_TYPES } from "@/modules/valvontatiedot/constants/Valvontatiedot";
import { Option } from "@/@types/Option";

export default defineComponent({
    props: {
        modelValue: Object as PropType<Option>,
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const valvontatyyppi = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });
        return { valvontatyyppi, valvontatyypit: VALVONTA_TYPES, multipleSelectionLabel };
    },
});
